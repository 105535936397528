import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './api/rem'
// axios、ajax封装
import api from './api/api'
import * as http from './api/request'
import modal from '@/utils/modal'
// 密码框
import PassInput from '@/components/passwordInput/index.vue'
Vue.component('PassInput', PassInput)
Vue.prototype.$modal = modal

Vue.prototype.$http = http
Vue.prototype.$api = api

Vue.config.productionTip = false
Vue.use(ElementUI)



new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
